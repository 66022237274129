<template>
  <div>
    <p>实验任务</p>
    <form class="login" @submit.prevent="getSample">
      <div>
        <label>样品编号</label>
        <input
          required
          v-model="sampleno"
          type="text"
          placeholder="2020-GJJ-0001"
        />
        <button class="buttongrap" type="submit">查询</button>
        <span>请按此格式输入:2020-GJJ-0001</span>
      </div>
    </form>
    <div v-if="sample">
      <p>状态{{ statusflags.find((c) => c.id === sample.status).name }}</p>
      <p>
        样品编号:{{ samplenonew
        }}<button @click.prevent="openOnline(sample.id, key)">
          在线审查报告</button
        ><button class="buttongrap" @click.prevent="wpsOpenReport(sample.id, key)">
          用wps打开报告
        </button>
      </p>
      <p>任务编号:{{ trustno }}</p>
      <p>样品名称:{{ sample.samplenickname }}</p>
      <ol>
        <li v-for="item in sample.arguments" :key="item.id">
          {{ item.stdcode }}{{ item.name }}
        </li>
      </ol>

      <p>检测依据</p>
      <ol>
        <li v-for="item in sample.accordings" :key="item.id">
          {{ item.stdcode }}{{ item.name }}
        </li>
      </ol>
      <p>判定依据</p>
      <ol>
        <li v-for="item in sample.standards" :key="item.id">
          {{ item.stdcode }}{{ item.name }}
        </li>
      </ol>
      <p>备注:{{ sample.remark }}</p>
      <Report :samplecode="samplecode" :sampleno="sampleno" :sample="sample" />
    </div>
    <div v-else></div>
  </div>
</template>

<script>
import { ref } from "vue";
import Report from "@/components/Report";
import { invokewps } from "../components/sdk";
import { baseUrl, statusflags } from "@/config.js";
export default {
  name: "Sample",
  components: {
    Report,
  },
  setup() {
    const sampleno = ref("2020");
    const samplecode = ref("");
    return { samplecode, sampleno, statusflags };
  },
  data() {
    return {
      sample: null,
    };
  },

  computed: {
    trustno() {
      const temp = `${
        this.sample?.trust.year
      }-${this.sample?.trust.trustnumber.toString().padStart(4, "0")}`;
      if (this.sample?.trust.certificate === 0) {
        // 公路
        return `JHRW-${temp}`;
      } else {
        return `JHSRW-${temp}`;
      }
    },
    samplenonew() {
      return `JHYP-${this.sample?.year}-${
        this.sample?.samplecode
      }-${this.sample?.samplenumber.toString().padStart(4, "0")}`;
    },
    key() {
      return `${this.sample?.year}-${
        this.sample?.samplecode
      }-${this.sample?.samplenumber.toString().padStart(4, "0")}.xlsx`;
    },
  },

  // mounted() {
  //   this.getSample();

  // },
  methods: {
    async getSample() {
      if (!(this.sampleno.length === 13 || this.sampleno.length === 11)) {
        return;
      }

      let year = "";
      let samplenumber = "";
      if (this.sampleno.includes("-") && this.sampleno.length === 13) {
        const temp = this.sampleno.split("-");
        year = parseInt(temp[0]);
        this.samplecode = temp[1].toString().toUpperCase();
        samplenumber = parseInt(temp[2]);
      } else {
        year = parseInt(this.sampleno.substring(0, 4));
        this.samplecode = this.sampleno.substring(4, 7).toUpperCase();
        samplenumber = parseInt(this.sampleno.substring(7, 11));
      }

      try {
        const response = await fetch(
          `${baseUrl}/api/v1/sample/samplenumber/${year}/${this.samplecode}/${samplenumber}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json;charset=utf-8",
              Authorization: "Bearer " + this.$store.state.auth.token,
            },
          }
        );
        if (response.ok) {
          const data = await response.json();
          this.sample = data;
        } else {
          this.sample = null;
        }
      } catch (error) {
        this.sample = null;
        console.error(error);
      }
    },

    async openOnline(sample_id, key) {
      try {
        const response = await fetch(
          `${baseUrl}/wps/excelurl/1/0/${sample_id}/${key}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json;charset=utf-8",
              Authorization: "Bearer " + this.$store.state.auth.token,
            },
          }
        );
        if (response.ok) {
          const data = await response.json();
          const url = data.url;
          sessionStorage.jurl = url;
          const jump = this.$router.resolve({ name: "excelcontainer" });
          window.open(jump.href, "_blank");
        } else {
          alert("没有提交过,请先提交");
        }
      } catch (error) {
        console.error(error);
      }
    },

    async wpsOpenReport(sample_id, key) {
      try {
        const response = await fetch(
          `${baseUrl}/api/v1/wpsfile/hasreport/${sample_id}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json;charset=utf-8",
              Authorization: "Bearer " + this.$store.state.auth.token,
            },
          }
        );
        if(!response.ok){
          alert("没有提交过,请先提交");
          return;
        }
        const json = await response.json();
        if (json.status < 2) {
          alert("没有提交过,请先提交");
          return;
        }
        const userStr = JSON.stringify(this.$store.state.auth.user);
        const sampleStr = JSON.stringify(this.sample);
        const oafun = {
          openOnline: {
            token: this.$store.state.auth.token,
            user: userStr,
            fname: `A1/${key}`,
            sample: sampleStr,
          },
        };
        const json1 = await invokewps(oafun);
        console.log(json1);
      } catch (e) {
        alert(e.message);
      }
    },
  },
};
</script>

<style scoped>
p {
  line-height: 20px;
  padding: 0;
  margin: 0;
}
.buttongrap {
  margin-left: 10px;
}
</style>

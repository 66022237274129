<template>
  <div class="grid">
    <div>
      <p>请选择报告模板并点下载</p>
      <div class="card" style="width: 80rem;">
        <ul class="list-group list-group-flush">
          <li
            class="list-group-item"
            v-for="(report, index) in reportItems"
            :key="report.id"
          >
            {{ index + 1 }}.{{ report.stdcode }}{{ report.name
            }}<button @click.prevent="downloadReoprt(report.stdcode + '.xlsx')">
              使用此模板
            </button>
            <button
              class="buttongrap"
              @click.prevent="invokewps1(report.stdcode + '.xlsx')"
            >
              用wps看模板
            </button>

            <button
              class="buttongrap"
              @click.prevent="
                openOnline(report.id, 0, report.stdcode + '.xlsx')
              "
            >
              在线查看模板
            </button>
          </li>
        </ul>
      </div>

      <p>{{ itemLength }}</p>
    </div>
  </div>
</template>

<script>
import { baseUrl } from "@/config.js";
import { onMounted, ref, computed, watch } from "vue";
import { invokewps } from "./sdk.js";
import auth from "@/store/modules/auth";

export default {
  name: "Report",

  props: {
    samplecode: {
      type: String,
      require: true,
    },
    sampleno: {
      type: String,
      require: true,
    },
    sample: {
      type: Object,
      require: true,
    },
  },
  setup(props) {
    const reportItems = ref([]);
    const itemLength = computed(() => reportItems.value.length);
    const getReport = async (samplecode) => {
      if (!props.samplecode) {
        return;
      }
      try {
        const response = await fetch(
          `${baseUrl}/api/v1/report/listbysamplecode/${samplecode}`,
          {
            method: "get",
            headers: {
              "Content-Type": "application/json;charset=utf-8",
              Authorization: "Bearer " + auth.state.token,
            },
          }
        );
        const data = await response.json();
        reportItems.value = data;
      } catch (error) {
        console.error(error);
      }
    };

    onMounted(() => {
      getReport(props.samplecode);
    });
    // watchEffect(()=>{
    //   samplecode1.value=props.samplecode;
    // });
    watch(
      () => props.samplecode,
      (newVal) => {
        getReport(newVal);
      }
    );

    return { reportItems, itemLength };
  },

  methods: {
    async getReportItems(a) {
      try {
        const response = await fetch(
          `${baseUrl}/api/v1/report/listbysamplecode/${a}`,
          {
            method: "get",
            headers: {
              "Content-Type": "application/json;charset=utf-8",
              Authorization: "Bearer " + this.$store.state.auth.token,
            },
          }
        );
        const data = await response.json();
        this.reportItems = data;
      } catch (error) {
        console.error(error);
      }
    },

    async openOnline(report_id, sample_id, key) {
      try {
        const response = await fetch(
          `${baseUrl}/wps/excelurl/0/${report_id}/${sample_id}/${key}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json;charset=utf-8",
              Authorization: "Bearer " + this.$store.state.auth.token,
            },
          }
        );
        const data = await response.json();
        const url = data.url;
        sessionStorage.jurl = url;
        const jump = this.$router.resolve({ name: "excelcontainer" });
        window.open(jump.href, "_blank");
      } catch (error) {
        alert.error(error);
      }
    },
    // 用wps 下载模板做报告
    async downloadReoprt(fname) {
      const userStr = JSON.stringify(this.$store.state.auth.user);
      const sampleStr = JSON.stringify(this.sample);
      const oafun = {
        downloadReoprt: {
          token: this.$store.state.auth.token,
          user: userStr,
          fname: fname,
          sample: sampleStr,
        },
      };
      const json = await invokewps(oafun);
      console.log(json);
    },
    // 用wps在线打开模板
    async invokewps1(fname) {
      const userStr = JSON.stringify(this.$store.state.auth.user);
      const sampleStr = JSON.stringify(this.sample);
      const oafun = {
        openOnline: {
          token: this.$store.state.auth.token,
          user: userStr,
          fname: `template/${fname}`,
          sample: sampleStr,
        },
      };
      const json = await invokewps(oafun);
      console.log(json);
    },

    // 停用
    async addfile(sample_id, report_id, key) {
      try {
        const response = await fetch(
          `${baseUrl}/wps/addfile/${sample_id}/${report_id}/${key}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json;charset=utf-8",
              Authorization: "Bearer " + this.$store.state.auth.token,
            },
          }
        );
        const data = await response.json();
        const url = data.url;
        sessionStorage.jurl = url;
        const jump = this.$router.resolve({ name: "excelcontainer" });
        window.open(jump.href, "_blank");
      } catch (error) {
        console.error(error);
      }
    },
  },
};
</script>

<style scoped>
.buttongrap {
  margin-left: 10px;
}
</style>
